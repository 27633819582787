import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import ButtonLink from "../ButtonLink"

const Banner2Card = ({
  aboutCardFluid,
  lightTitle = true,
  text = "Lorem Ipsum! Lorem ipsum, or lipsum as it is sometimes known, is dummy text. Lorem ipsum, or lipsum as it is sometimes known, is dummy text. Lorem ipsum, or lipsum as it is ometimes known, is dummy text.Lorem ipsum, or lipsum as it is sometimes known, is dummy text. Lorem ipsum, or lipsum as it is sometimes known.",
  header = "Title",
  date = "No date",
  slug = "#",
  postImage = null,
}) => {
  const data = useStaticQuery(graphql`
    query Banner2CardQuery {
      defaultImage: file(relativePath: { eq: "WINKS Logo - Circle Only.png" }) {
        id
        childImageSharp {
          gatsbyImageData(height: 200, aspectRatio: 1)
        }
      }
    }
  `)
  const defaultImage = data.defaultImage.childImageSharp.gatsbyImageData
  const imageForPost = postImage || defaultImage

  return (
    <div className="flex flex-col mt-10 md:space-x-10 md:flex-row mx-5 md:mx-10 p-5 md:p-0 items-center max-w-5xl bg-black bg-opacity-10 md:bg-opacity-0 ">
      <div className=" w-52">
        <GatsbyImage
          image={imageForPost}
          className="object-cover rounded-full shadow-extra"
          alt=""
        />
      </div>

      <div
        className={` text-xl  grid  p-5 md:bg-black md:bg-opacity-10 rounded mt-5 md:mt-0 w-full ${
          lightTitle ? "text-secondary" : "text-primary"
        } `}
      >
        <h3
          className={`overflow-hidden ${
            lightTitle ? "text-secondary" : "text-primary"
          }  text-4xl text-center  md:text-left mb-2`}
        >
          {header}
        </h3>

        <h4 className="text-center md:text-left italic mb-3 ">
          Published: {date}
        </h4>
        <p className="mb-5">{text}</p>
        <ButtonLink
          text="Read More"
          to={`/news/${slug}`}
          className="justify-self-center md:justify-self-start font-bold tracking-wide"
        />
      </div>
    </div>
  )
}

export default Banner2Card
