import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { addDateToSortByField, sortPostByDate } from "../../helperFunctions"
import Banner2Card from "./banner2Card"
import { getGatsbyImageData } from "gatsby-source-sanity"
import { sanityConfig } from "../../sanityConfig"
import ShowMoreButton from "../ShowMoreButton"

const RenderNewsPosts = ({
  limit = null,
  lightTitle = true,
  hideShowMoreButton = false,
}) => {
  // Set the number of posts to be displayed by default to state, and create a state update function
  const [numOfPosts, setNumOfPosts] = useState(limit || 5)

  function addMoreCards() {
    return setNumOfPosts(numOfPosts + 5)
  }

  const { postsWithCustomDate, allPosts } = useStaticQuery(graphql`
    query RenderNewsPostsQuery {
      postsWithCustomDate: allSanityBlogPost(
        filter: { publishedDate: { ne: null } }
        sort: { fields: publishedDate }
      ) {
        edges {
          node {
            id
            title
            _createdAt
            publishedDate
            summary
            slug {
              current
            }
            featureImage {
              asset {
                id
                url
                gatsbyImageData(
                  width: 200
                  aspectRatio: 1
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
      allPosts: allSanityBlogPost(
        sort: { fields: [_createdAt], order: [DESC] }
      ) {
        edges {
          node {
            id
            title
            _createdAt
            publishedDate
            summary
            slug {
              current
            }
            featureImage {
              asset {
                id
                gatsbyImageData(width: 200, aspectRatio: 1)
              }
            }
          }
        }
      }
    }
  `)

  // allPosts was sorted by _createdAt to ensure that only most recent are provided
  // filter out records with a custom date:
  const allPostsNoCustomDate = allPosts.edges.filter(
    ({ node: post }) => post.publishedDate === null
  )

  //   combine posts with customDate and allPostsNoCustomDate
  const combinedPosts = allPostsNoCustomDate.concat(postsWithCustomDate.edges)
  const arrayWithNewDateField = addDateToSortByField(combinedPosts)
  let sortedDatesArray = sortPostByDate(arrayWithNewDateField)

  // limit the number of posts to display

  sortedDatesArray = sortedDatesArray.slice(0, numOfPosts)

  if (sortedDatesArray.length > 0) {
    return (
      <div className="grid">
        {sortedDatesArray.map((post, index) => {
          // FIX
          const imageData = post?.featureImage?.asset?.gatsbyImageData || null

          return (
            <Banner2Card
              lightTitle={lightTitle}
              header={post.title}
              text={post.summary}
              date={post.dateString}
              slug={post.slug.current}
              postImage={imageData || null}
              key={`${index}-${post.slug.current}`}
            />
          )
        })}
        {/* Only show button when there are more cards to show */}
        {numOfPosts >= 1 &&
          numOfPosts <= sortedDatesArray.length &&
          !hideShowMoreButton && (
            <ShowMoreButton addMoreCards={addMoreCards} text="More Posts" />
          )}
      </div>
    )
  } else {
    return (
      <div className={`p-10 grid w-full justify-items-center  } `}>
        <h2 className="mt-10 text-4xl text-center font-bold">
          Sorry, no posts yet. Check back again soon!
        </h2>
      </div>
    )
  }
}

export default RenderNewsPosts
