import React from "react"
import Hero from "../Hero"
import ButtonLink from "../ButtonLink"
import WinksLogo from "../svgs/WinksLogo"
import Gradient from "../Gradient"

const IndexHero = ({ heroFluid }) => {
  const gridPosition = `justify-center`

  return (
    <Hero
      props={{
        heroFluid,
        gridPosition,
        className: "bg-black bg-opacity-40 relative",
      }}
    >
      <div className="grid items-center my-20 z-20 pb-20 md:pb-0 ">
        <div className="grid md:grid-flow-col  justify-items-center items-start ">
          <WinksLogo className="fill-white w-52 mb-10 md:mb-0 md:mr-10" />
          <div className="text-charcoal text-4xl md:text-5xl max-w-2xl text-center md:text-left font-semibold">
            <div className="italic grid  ">
              {/* Supporting the
              <br /> */}
              <span className="text-white font-roboto not-italic text-6xl mx-2">
                You are welcome here.
              </span>
              <span className="mt-4 text-white mt-10 md:mt-6 mx-10 md:mx-0">
                Providing a home away from home for First Nations youth.
              </span>
            </div>
            <div className="mt-10 md:mt-5">
              <ButtonLink
                text="Learn More"
                className="px-5 py-1 text-2xl tracking-wide font-bold"
                to="/about"
              />
            </div>
          </div>
        </div>
      </div>
      <Gradient className="absolute bottom-0 h-1/4 from-transparent to-gradientGray z-10" />
    </Hero>
  )
}

export default IndexHero
