import React from "react"
import { graphql } from "gatsby"
import { convertToBgImage } from "gbimage-bridge"

import BannerTitleNew from "../components/BannerTitleNew"
import Layout from "../components/layout"
import SEO from "../components/SEO"

import BannerContainer from "../components/bannerContainer"
import PageContentContainer from "../components/PageContentContainer"
import IndexHero from "../components/IndexHero"
import WaveDivider from "../components/svgs/WaveDivider"
import RenderNewsPosts from "../components/index/RenderNewsPosts"

import RenderEvents from "../components/events/RenderEvents"
import ButtonLink from "../components/ButtonLink"
import PageSectionContent from "../components/PageSection"

const Index = ({ data }) => {
  const { banner1 } = data

  const banner1Fluid = banner1.banner1HeroImage.asset.gatsbyImageData

  const bgImage = convertToBgImage(banner1Fluid)

  const images = {
    banner1Fluid: banner1Fluid,
  }

  const backgroundFluidImageStack = [
    bgImage.fluid,
    `linear-gradient(0deg, rgba(71,71,71,1) 12%, rgba(71,71,71,0.01) 78%)`,
  ].reverse()

  const heroProps = {
    heroFluid: bgImage.fluid,
  }

  const LI = ({ children, day }) => (
    <li>
      <span className="font-bold">{day}</span>- {children}
    </li>
  )

  return (
    <Layout>
      <SEO title="Home" />

      <IndexHero {...heroProps} />

      <PageContentContainer>
        {/* Latest News Section */}
        <BannerContainer
          className="bg-gradient-to-b from-gradientGray via-gradientGrayTransparent to-gradientGrayTransparent py-20 "
          children={
            <div className="grid gap-y-20">
              <section>
                <BannerTitleNew
                  className="mb-10"
                  props={{
                    divider: (
                      <WaveDivider className="hidden md:block fill-tertiary w-12 md:w-40" />
                    ),

                    doubleDivider: true,
                    text: (
                      <div className="grid justify-items-center">
                        <h2 className="text-5xl font-semibold text-center ">
                          Hours &amp; Ice Times
                        </h2>
                        <WaveDivider className="fill-tertiary px-5 w-1/2 mt-5 md:hidden" />
                      </div>
                    ),
                  }}
                />
                <PageSectionContent className="text-2xl">
                  <div className="grid gap-y-6 leading-snug">
                    <p className="text-center">
                      We're open Monday-Friday, 3:00pm - 8:30pm
                    </p>
                    <p className="text-center">
                      <span className="font-bold">Ice time:</span>
                      <br />
                      Mondays, 5:00pm - 6:00pm
                    </p>
                  </div>
                </PageSectionContent>
              </section>
              <section>
                <BannerTitleNew
                  className="mb-10"
                  props={{
                    divider: (
                      <WaveDivider className="hidden md:block fill-tertiary w-12 md:w-40" />
                    ),

                    doubleDivider: true,
                    text: (
                      <div className="grid justify-items-center">
                        <h2 className="text-5xl font-semibold ">Welcome</h2>
                        <WaveDivider className="fill-tertiary px-5 w-1/2 mt-5 md:hidden" />
                      </div>
                    ),
                  }}
                />
                <PageSectionContent className="text-2xl">
                  <div className="grid gap-y-6 leading-snug">
                    <p className="">
                      Welcome to the W.I.N.K.S. Active Wellness Program website!
                      Since 2017 we’ve offered safe spaces where First Nations
                      students new to our community can engage in programs and
                      activities that support their physical, social, mental,
                      and cultural well-being. For many of the students we
                      serve, a transition away from their homes and communities
                      can be difficult, and our goal is to be a place of
                      security and comfort – offering a home away from home
                      during an often challenging time.
                    </p>
                    <p>
                      We’d love to share more about our mission, vision, and
                      programming with you – so feel free to navigate our site
                      to get a better sense of who we are. If you have any
                      questions or inquiries about our organization we’d love to
                      hear from you.
                    </p>
                  </div>
                </PageSectionContent>
              </section>
            </div>
          }
        />
        {/* Latest News Section */}
        <BannerContainer
          color="bg-secondary"
          className="text-primary py-20 "
          children={
            <>
              <BannerTitleNew
                className="mb-5"
                props={{
                  divider: (
                    <WaveDivider className="hidden md:block fill-tertiary w-12 md:w-40" />
                  ),

                  doubleDivider: true,
                  text: (
                    <div className="grid justify-items-center">
                      <h2 className="text-4xl font-semibold">Latest News</h2>
                      <WaveDivider className="fill-tertiary px-5 w-1/2 mt-5 md:hidden" />
                    </div>
                  ),
                }}
              />
              <div className=" grid gap-y-2 justify-center mt-10 ">
                <div className="flex flex-col gap-y-5">
                  <RenderNewsPosts
                    limit={3}
                    lightTitle={false}
                    hideShowMoreButton
                  />
                </div>
                {/* <div className="grid grid-cols-1 w-1/12 text-2xl mt-ten ml-twen mb-ten bg-green-600">
                  <button type="submit" className="" style={{ marginTop: 0 }}>
                    Learn More
                  </button>
                </div> */}
                <ButtonLink
                  text={null}
                  to="/news"
                  className="justify-self-center tracking-wider font-bold text-2xl mt-5 px-6 py-2 "
                >
                  <span>See All Posts</span>
                </ButtonLink>
              </div>
            </>
          }
        />

        {/* "Upcoming Events" Section */}
        <BannerContainer
          color="bg-primary"
          className="py-20"
          children={
            <>
              <BannerTitleNew
                props={{
                  divider: (
                    // <Divider size={"h-6 w-1/2"} color={"bg-about-lineSVG2"} />
                    <WaveDivider className="hidden md:block fill-tertiary w-12 md:w-40" />
                  ),
                  divStyle: "h-1/6 w-full place-items-center",
                  doubleDivider: true,
                  text: (
                    <div className="grid justify-items-center">
                      <h3 className="text-4xl font-semibold">
                        Upcoming Events
                      </h3>
                      <WaveDivider className="fill-tertiary px-5 w-1/2 mt-5 md:hidden" />
                    </div>
                  ),
                }}
              />
              <div className="flex flex-wrap  mt-10 justify-center">
                {/* Add a limit to the number of upcoming events to display? */}
                <RenderEvents limit={3} hideShowMoreButton />
              </div>
              <div className="flex justify-center mt-10">
                <ButtonLink
                  text={null}
                  className="flex items-center justify-self-center text-base md:text-2xl font-semibold tracking-wider px-6 py-2 ml-5 mb-5"
                  to="/events"
                >
                  <span>All Events</span>
                </ButtonLink>
              </div>
            </>
          }
        />
      </PageContentContainer>
    </Layout>
  )
}

export const query = graphql`
  query IndexPageQuery {
    banner1: sanityHomePage {
      banner1HeroImage {
        asset {
          gatsbyImageData(width: 1200, placeholder: BLURRED)
          id
          url
        }
      }
    }
  }
`

export default Index
