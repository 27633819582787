import React from "react"

const PageSectionContent = ({ children, className = "" }) => {
  return (
    <div
      className={`flex flex-col items-center mx-3 md:mx-10 xl:mx-20 max-w-7xl ${className}`}
    >
      {children}
    </div>
  )
}

export default PageSectionContent
